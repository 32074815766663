<template>
  <projects-layout :projects="projects">
    <template v-slot:project="{ project }">
      <agenda-project :project="project"></agenda-project>
    </template>
  </projects-layout>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("agenda");

export default {
  name: "AgendaProjects",
  components: {
    ProjectsLayout: () => import("@/components/project/ProjectsLayout"),
    AgendaProject: () => import("@/views/agenda/AgendaProject")
  },
  computed: {
    ...mapState({ projects: "projects" })
  }
};
</script>
